import axios from "@/utils/axios";

export default {
    namespaced: true,
    state: () => ({
        currencyTypes: [],
        pendingPayments: [],
        paymentDetails: {},
        regionalPurchases: [],
    }),
    getters: {

    },
    mutations: {
        setCurrencyTypes(state, types){
            console.log('wtf',types);
            state.currencyTypes = types.filter(el=>(el!=null));
        },
        setPendingPayments(state, list) {
            state.pendingPayments = list;
        },
        addPaymentDetails(state, payload) {
            state.paymentDetails[payload.payment.id] = payload;
        },
        setRegionalPurchases(state, list) {
            state.regionalPurchases = list;
        },
    },
    actions:{
        createInvoice(ctx, data) {
            // Example:  data = {amount_usd: 1000, currency: 'BTC'}
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/create_invoice', data).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        iHavePaid(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/user/i_have_paid', data).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        createTestpay(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/api/v1/testing/test_pay', data).then(response => {
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },
        currencies({commit}){
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/currencies/get/').then(response => {
                    const tmpObj = response.data.reduce((obj,el)=> {
                        if (el.category) obj[el.category] = 1;
                        return obj;
                    },{} );
                    commit('setCurrencyTypes', Object.keys(tmpObj));
                    resolve(response.data);
                }).catch(error => reject(error));
            });
        },

        getPendingPayments({commit}) {
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/user/get_pending_payments/').then(({data}) => {
                    commit('setPendingPayments', data);
                    resolve(data);
                }).catch(error => reject(error));
            });
        },

        getBalanceRequest(ctx,id){
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/user/get_balance_request/'+id).then(({data}) => {
                    console.log('br',data);
                    resolve(data);
                }).catch(error => reject(error));
            });
        },


        getPaymentDetails({state,commit},id){
            return new Promise((resolve, reject) => {
                console.log('state.paymentDetails', state.paymentDetails);
                if (state.paymentDetails[id]) {
                    resolve(state.paymentDetails[id]);
                }else
                    axios.get('/api/v1/transactions/get_payment_details/',{params:{id:id}}).then(({data})=>{
                        commit('addPaymentDetails', data);
                        resolve(data);
                    }).catch(e=>{reject(e)})
            });
        },

        getRegionalPurchases({commit}) {
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/transactions/get_regional_purchases/').then(({data}) => {
                    commit('setRegionalPurchases', data);
                    resolve(data);
                }).catch(error => reject(error));
            });
        },


    }
}
