<template>
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" id="crown">
        <rect width="30" height="30" fill="none"></rect>
        <path fill="none" stroke=" #BAC5C8" stroke-linecap="round" stroke-linejoin="round" stroke-width="12" d="M45.12859 196.01674a8.01266 8.01266 0 0 0 9.97356 5.86223 273.90053 273.90053 0 0 1 145.746-.0138 8.01268 8.01268 0 0 0 9.97132-5.85941l25.48872-108.3169a8 8 0 0 0-11.03641-9.143L174.688 101.02754a8 8 0 0 1-10.24237-3.42535l-29.4524-53.01433a8 8 0 0 0-13.98652 0L91.55434 97.60219A8 8 0 0 1 81.312 101.02754L30.72054 78.54246a8 8 0 0 0-11.03717 9.13973zM95.99993 161.6787a305.83767 305.83767 0 0 1 64.00009 0"></path>
    </svg>

</template>

<script>
export default {
  name: "CrownIcon"
}
</script>

<style scoped>

</style>
