<template>
    <div class="sidebar-wrapper" :class="{'thinSidebarActive': thinSidebar }">
        <router-link :to="{name:'home'}" class="sidebar-logo">
            <img src="@/assets/images/Logo.png"/>
        </router-link>
        <div class="burger-wrap">
            <img class="sidebar-logo-small" src="@/assets/images/LogoSmall.png"/>
            <div class="burger"
                 @click="isOpened = !isOpened"
                 :class="{'activebrgr': isOpened }">
                <div class="dash"></div>
                <div class="dash"></div>
                <div class="dash"></div>
                <div class="dash"></div>
            </div>
        </div>
        <div class="sidebar overflow-y-auto"
             :class="{'burgered': isOpened }"
        >

            <main-profile/>

            <div class="sidebar-items-wrap"
                 :class="{'burgered': isOpened }">

                <router-link :to="{name:'profile'}" class="sidebar-profile-item"
                     @click="closeBurger">
                    <div class="sidebar-profile-icon d-flex jc ac">
                        <ProfileIcon/>
                    </div>
                    <div class="sidebar-profile-name-group">
                        <span class="sidebar-profile-name d-flex ac">
                            <div>{{ user.first_name }}</div>
                            <span>|</span>
                            <div class="sidebar-profile-name-balance">{{ user.balance }} $</div>
                        </span>
                    </div>
                </router-link>

                <LanguageSwitcher/>

                <div class="sidebar-items main">
                    <div v-for="page in getMainMenu.filter(el=>!el.hide)"
                         :key="page.name">
                        <router-link v-if="!page.child"
                                     :to="{name: page.name}"
                                     :class="{
                                        burgered: isOpened,
                                        notHome: page.name!=='home'
                                     }"
                                     @click="closeBurger"
                                     class="sidebar-item__parent"
                        >

                            <Component :is="page.svg"/>
                            <span>{{ page.title }}</span>
                        </router-link>
                        <div v-else
                             @click="curOpened === page.name? curOpened = '':curOpened=page.name"
                             :class="{'activeNow':$route.path.indexOf(page.name) === 1}"
                             class="sidebar-item__parent parent">
                            <Component :is="page.svg"/>
                            <span>{{ page.title }}</span>
                        </div>
                        <ul v-if="(page.child && page.name===$route.name) || page.child && $route.path.indexOf(page.name) === 1 || this.curOpened === page.name"
                            class="child">
                            <li v-for="child in page.child.filter(el=>!el.hide)" :key="child.name">
                                <router-link :to="{name: child.name}"
                                             :class="{burgered: isOpened}"
                                             @click="closeBurger"
                                             class="sidebar-item__pass"
                                >
                                    <span>{{ child.title }}</span>
                                </router-link>
                            </li>


                        </ul>

                    </div>
<!--                    <div class="support">-->
<!--                        <div class="support-title" @click="isOpenSupport=!isOpenSupport">-->
<!--                            <NewsIcon/>-->
<!--                            Support-->
<!--                        </div>-->
<!--                        <template v-if="isOpenSupport">-->
<!--                            <a target="_blank" href="https://t.me/elatomsupport" class="sidebar-items-support"-->
<!--                               :key="'telegram'">-->
<!--                                <telegram-icon class="support-icon"/>-->
<!--                                Telegram</a>-->
<!--                            <a target="_blank" href="https://wa.me/79138860974" class="sidebar-items-support"-->
<!--                               :key="'watsapp'">-->
<!--                                <whatsapp-icon class="support-icon"/>-->
<!--                                WatsApp</a>-->
<!--                            <a target="_blank" href="https://viber.click/79138860974" class="sidebar-items-support"-->
<!--                               :key="'watsapp'">-->
<!--                                <ViberIcon class="support-icon"/>-->
<!--                                Viber</a>-->
<!--                        </template>-->
<!--                    </div>-->

                </div>


                <div class="sidebar-items extra">
                    <router-link :to="{name: page.name}"
                                 v-for="page in extraMenu"
                                 :key="page.name"
                                 @click="closeBurger"
                    >
                        <Component :is="page.svg"/>
                        <span>{{ page.title }}</span>
                    </router-link>
                    <router-link :to="{name: 'login'}"
                                 v-for="page in exit"
                                 :key="page.name"
                                 @click="logout"
                    >
                        <Component :is="page.svg"/>
                        <span>{{ page.title }}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import MainProfile from "@/components/MainProfile";
import HideIcon from "@/assets/icons/hideIcon";
import CheckmarkIcon from "@/assets/icons/CheckmarkIcon";
import StarIcon from "@/assets/icons/StarIcon";
import EventsIcon from "@/assets/icons/EventsIcon";
import NewsIcon from "@/assets/icons/NewsIcon";
import PartnerIcon from "@/assets/icons/PartnerIcon";
import CrownIcon from "@/assets/icons/CrownIcon";
import adminIcon from "@/assets/icons/adminIcon.vue";
import WalletsIcon from "@/assets/icons/WalletsIcon";
import HomeIcon from "@/assets/icons/HomeIcon";
import DollarIcon from "@/assets/icons/DollarIcon";
import LogoutIcon from "@/assets/icons/LogoutIcon";
import CommunityIcon from "@/assets/icons/CommunityIcon";
import QuestionMark from "@/assets/icons/QuestionMark";
import {mapGetters, mapState} from "vuex";
import WhatsappIcon from "@/assets/icons/whatsappIcon.vue";
import TelegramIcon from "@/assets/icons/teltegramIcon.vue";
import ViberIcon from "@/assets/icons/viberIcon.vue";
import ProfileIcon from "@/assets/icons/ProfileIcon.vue";
import LanguageSwitcher from "@/components/common/LanguageSwitcher";

export default {
    name: "MainMenu",
    components: {
        LanguageSwitcher,
        ProfileIcon,
        ViberIcon,
        TelegramIcon,
        WhatsappIcon,
        QuestionMark,
        CommunityIcon,
        adminIcon,
        HomeIcon,
        WalletsIcon,
        CrownIcon,
        DollarIcon, PartnerIcon, NewsIcon, EventsIcon, StarIcon, CheckmarkIcon, HideIcon, MainProfile, LogoutIcon
    },
    props:{
        allowPage: {
            type: Array,
            default() {
                return [];
            },
        }
    },
    data() {
        return {
            curOpened: '',
            thinSidebar: false,
            isOpened: false,
            isOpenSupport: false,

            extraMenu: [
                {
                    title: this.$t("side_bar.about"),
                    name: 'about',
                    svg: 'CommunityIcon'
                },
                {
                    title: this.$t("side_bar.contacts"),
                    name: 'contacts',
                    svg: 'CommunityIcon'
                },
                {
                    title: this.$t("side_bar.community"),
                    name: 'community',
                    svg: 'CommunityIcon'
                },
                // {
                //     title: this.$t("side_bar.knowledge_base"),
                //     name: '',
                //     svg: 'QuestionMark',
                // },
            ],
            exit: [
                {
                    title: this.$t("login_page.logout"),
                    name: 'login',
                    svg: 'LogoutIcon',
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            isAdmin: 'user/isAdmin',
            isSupport: 'user/isSupport',
        }),

        ...mapState({
            user: state => state.user.data,
        }),


        mainMenu() {
            return [
                {
                    title: this.$t("side_bar.home"),
                    name: 'home',
                    svg: 'HomeIcon',
                },
                {
                    title: this.$t("side_bar.wallet"),
                    name: 'wallet',
                    svg: 'WalletsIcon',
                },
                {
                    title: this.$t("side_bar.investments"),
                    name: 'investments',
                    svg: 'DollarIcon',
                    child: [
                        {
                            title: this.$t('side_bar.available_packages'),
                            name: 'investments',
                        },
                        {
                            title: this.$t('side_bar.my_investments'),
                            name: 'investments.my',
                        },
                    ]
                },
                {
                    title: this.$t("side_bar.partners"),
                    name: 'partners',
                    svg: 'PartnerIcon',
                    child: [
                        {
                            title: this.$t("side_bar.plan"),
                            name: 'partners',
                        },
                        {
                            title: this.$t("side_bar.team"),
                            name: 'partners.team',
                        },
                        {
                            title: this.$t("side_bar.instruments"),
                            name: 'partners.instruments',
                        },
                        /* {
                             title: this.$t("side_bar.education"),
                             name: 'partners.education',
                         },*/
                        //{
                        //    title: this.$t("side_bar.materials"),
                        //    name: 'partners.materials',
                        //},
                    ]
                },
                {
                    hide: !this.user.settings.reg_leader,
                    title: this.$t("side_bar.representation"),
                    name: 'representation',
                    svg: 'CrownIcon'
                },
                {
                    title: this.$t("side_bar.news"),
                    name: 'news',
                    svg: 'NewsIcon'
                },
                {
                    hide: !this.isAdmin && !this.isSupport,
                    title: this.$t("side_bar.admin_panel"),
                    name: 'admin',
                    svg: 'adminIcon',
                    child: [
                        {
                            title: this.$t("side_bar.investor_base"),
                            name: 'admin',
                        },
                        {
                            hide: !this.isAdmin,
                            title: this.$t("side_bar.finance"),
                            name: 'admin.finance',
                        },
                        {
                            title: this.$t("side_bar.withdrawals"),
                            name: 'admin.withdrawal',
                        },
                        {
                            title: this.$t("side_bar.payment-requests"),
                            name: 'admin.payment-requests',
                        },
                    ]
                }
            ];
        },

        getMainMenu() {
            // Если allowPage пустой, возвращаем оригинальное меню без изменений
            if (this.allowPage.length === 0) {
                return this.mainMenu;
            }
            // Копируем mainMenu, чтобы не изменять исходный массив
            return this.mainMenu.map(item => {
                // Проверка для основного пункта меню
                if (!this.allowPage.includes(item.name)) {
                    item.hide = true;
                }

                // // Проверка для дочерних элементов, если они существуют
                // if (item.child && Array.isArray(item.child)) {
                //     item.child = item.child.map(childItem => {
                //         if (!this.allowPage.includes(childItem.name)) {
                //             childItem.hide = true;
                //         }
                //         return childItem;
                //     });
                // }

                return item;
            });
        },
    },
    methods: {
        closeBurger() {
            this.isOpened = false;
        },
        logout() {
            this.$store.dispatch('user/logout');
            //this.$router.push({name: '/login'});
        },
    },
    mounted() {
        this.$store.dispatch('user/getUserManager')

    },
}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;

.burger {
    &-wrap {
        position: fixed;
        right: 0;
        top: 0;
        height: 40px;
        padding: 30px;
        z-index: 11;
        display: none;
        @include respond($laptop) {
            display: block;
        }
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50px;
    height: 32px;
    background: transparent;
    position: relative;
}

.burger .dash {
    width: 50px;
    height: 5px;
    background: #fff;
    border-radius: 20px;
    transition: 0.25s;
    @include respond(420px) {
        width: 40px;
    }
}

.burger .dash:nth-of-type(3) {
    position: absolute;
    top: 14px;
}

.activebrgr .dash:nth-of-type(1) {
    opacity: 0;
}

.activebrgr .dash:nth-of-type(4) {
    opacity: 0;
}

.activebrgr .dash:nth-of-type(3) {
    transform: rotate(45deg);
}

.activebrgr .dash:nth-of-type(2) {
    transform: rotate(-45deg);
}


.thinSidebar {
    cursor: pointer;

    &:hover {
        color: #BAC5C8;
    }
}

a, .parent {
    all: unset;

    &:hover {
        cursor: pointer;
        color: #fff;
    }
}

.sidebar {
    overflow: hidden;
    position: fixed;
    width: 360px;
    /* height: 100vh; */
    background: #2E3338;
    top: 91px;
    overflow-y: auto;
    bottom: 0;

    &::-webkit-scrollbar {
        //display: none;
    }

    &::-webkit-scrollbar {
        display: block;
        width: 10px;
    }

    // #E3EAED
    &::-webkit-scrollbar-track {
        border-radius: 6px;
        background: #5c6167;
    }

    &::-webkit-scrollbar-thumb {
        background: #1e2429;
        background: #2E3338;
        border-radius: 6px;
        border: 2px solid #5c6167; /* creates padding around scroll thumb */
        border-right: 0;
    }

    &:hover {

    }

    @include respond(1700px) {
        width: 18.75%;
    }
    @include respond($laptop) {
        margin: 0;
        height: auto;
        z-index: 10;
        width: 100%;
        top: auto;
    }

    &-logo {
        padding-top: 35px;
        width: 360px;
        background: #2E3338;
        position: fixed;
        // z-index: 1;
        @include respond(1700px) {
            width: 18.75%;
        }
        @include respond($laptop) {
            display: block;
            z-index: 11;
            padding-top: 14px;
            padding-left: 10px;
        }

        img {
            margin-left: 60px;
            @include respond($desktop) {
                margin-left: 6px;
            }
            @include respond($laptop){
                object-fit: none;
                width: 55px;
                height: 56px;
                object-position: left center;
            }
        }

        &-small {
            display: none;
            padding-top: 30px;
            background: #2E3338;
            position: fixed;
            // z-index: 1;
            @include respond(1700px) {
                display: none;
                width: 18.75%;
            }
            @include respond($laptop) {
                margin-left: 6px;
            }

            img {
                margin-left: 60px;
                @include respond($desktop) {
                    display: none;
                }
            }
        }
    }

    &-items {
        &-wrap {
            display: flex;
            flex-direction: column;
            gap: 80px;

            @include respond($laptop) {
                display: none;
                &.burgered {
                    height: calc(100vh - 100px);
                    margin-top: 100px;
                    overflow: auto;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 10px;
                    display: flex;
                    @include respond($mobile) {
                        display: flex;
                    }
                }
            }
        }

        &.main {
            color: #C5CFD1;
            font-weight: 800;
            font-size: 16px;
            padding-left: 60px;
            @include respond($desktop) {
                padding-left: 10px;
            }

            a, .parent {
                display: flex;
                gap: 16px;

                padding-top: 18px;
                padding-bottom: 18px;
                padding-left: 25px;
                padding-right: 20px;
            }

            .child {
                padding: 5px 0 15px;

                a {
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 10px;
                    margin-left: 44px;
                }
            }
        }

        &.extra {
            font-weight: 500;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.5);
            padding-left: 60px;
            @include respond(1500px) {
                padding-left: 10px;
            }

            a, .parent {
                display: flex;
                gap: 16px;

                padding-top: 18px;
                padding-bottom: 18px;
                padding-left: 25px;
                padding-right: 20px;
            }

            > div {
                display: flex;
                gap: 16px;

                padding-top: 18px;
                padding-bottom: 18px;
                padding-left: 25px;
                padding-right: 20px;
            }
        }

        .router-link-active.sidebar-item__parent.notHome, .router-link-exact-active.sidebar-item__parent, .sidebar-item__parent.activeNow {
            background: #3E76B5;
            border-radius: 16px 0px 0px 16px;
            color: #FFFFFF;

            &.burgered {
                border-radius: 16px 16px 16px 16px;
                background: transparent;
            }
        }

        .router-link-exact-active.sidebar-item__pass {
            color: #FFFFFF;
        }

        .child .currentpage {
            background: none;
        }

        &.gift {
            display: flex;
            flex-direction: row;
            gap: 0;
            width: 240px;
            height: 120px;
            padding-left: 16.67%;

            & > div:nth-child(1) {
                //background: url('../images/Gift box.png'), #DDE4E5;
                width: 50%;
            }

            & > div:nth-child(2) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-weight: 700;
                font-size: 18px;
                color: #3E76B5;
                background: #fff;
                width: 50%;

                div {
                    padding-left: 20px;
                }
            }
        }

        display: flex;
        flex-direction: column;

    }
}

.sidebar-items-support {
    display: flex;
    gap: 16px;
    margin-left: 10px;
    padding: 20px !important;
    //margin-left: 0 !important;

    &:hover {
        cursor: pointer;
        color: #fff;
    }
}

.support-title {
    display: flex;
    gap: 16px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 25px;
    padding-right: 20px;

    &:hover {
        cursor: pointer;
        color: #fff;
    }
}

.support-icon {
    max-width: 18px;
    max-height: 18px;
}
.sidebar {
    &-profile {
        padding: 74px 0 32px 70px;
        @include respond($desktop) {
            padding-left: 10px;
        }
        @include respond($laptop){
            padding:25px;
            position:fixed;
            right:0;top:0;
            background: #2E3338;
            width:100%;
            justify-content: flex-end;
            gap:30px;
            align-items:center;
            min-height: 85px;
        }
        @include respond(450px){
            gap:20px;
        }
        &-items{
            display: flex;
            //gap: 40px;
            align-items: center;
            justify-content:space-between;
            width:100%;
            &::v-deep(.block-switch){
                min-width: auto;
            }
            &::v-deep(.switch-content){
                position: static;
                height: 40px;
                display: flex;
                align-items: center;
                @include respond($laptop){
                    width: 65px;
                }
            }
            @include respond($laptop){
                justify-content: flex-end;
                margin-right: 70px;
                gap: 18px;
            }
        }
        &-item{
            display: none;
            align-items: center;
            padding: 7px 12px 7px 8px;
            margin-left:30px;
            background: #454A50;
            border-radius: 16px;
            height: 40px;
            width:fit-content;
            @include respond(600px){
                display:flex;
            }

        }
        &-icon {
            margin-right: 4px;

        }
        &-icon,&-logout{
            font-size: 18px;
            height: 1.25em;
        }
        &-logout{
            cursor:pointer;
            left: 233px;
            &:hover{
                svg{
                    stroke:#7ECAE0;
                }
            }
        }
        &-name {
            display: flex;

            gap: 9px;
            &-balance{
                justify-content: center;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &-group {
                font-weight: 500;
                font-size: 13px;
                color: rgba(255, 255, 255, 0.5);
                &>*+div{
                    margin-top:5px;
                    @include respond($laptop){
                        margin:0 0 0 15px;
                    }
                    @include respond(450px){
                        margin-left:10px;
                    }
                }
                @include respond($laptop){
                    display:flex;
                }
                @include respond(450px){
                    font-size: 12px;
                }
            }
            &>div:first-child{
                width:min-content;
            }
            font-size: 11px;
            line-height: 140%;
            @include respond($mobile){
                font-size:15px;
            }
            line-height: 1.25;
            text-decoration: none;
            color: #fff;
            svg{
                margin-left:10px;
            }
            &:hover{
                color:#7ECAE0;
                svg{
                    stroke:#7ECAE0;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    ::v-deep .sidebar .block-switch {
        padding-left: 31px;
        align-items: baseline;
        margin-top: 26px;
        margin-bottom: 26px;
    }
}
@media (min-width: 1101px) {
    ::v-deep .sidebar .block-switch{
        align-items: end;
        padding-right: 21px;
    }
    .sidebar-items.main .a {
        padding-left: 15px;
    }
}


</style>
