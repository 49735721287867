import { createRouter, createWebHistory } from 'vue-router'
import DashboardLayout from "@/DashboardLayout";
import MishaRoutes from './trashbinForMerge/MishaRoutes';
import VolodyaRoutes from './trashbinForMerge/VolodyaRoutes';


const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/LoginPage.vue'),
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/pages/LoginPage.vue'),
    },
    {
        path: '/verify-email',
        name: 'verifyEmail',
        component: () => import('@/pages/VerifyPage.vue'),
    },

    {
        path: '/reset-password',
        name: 'resetPassword',
        component: () => import('@/pages/ResetPassword.vue'),
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/pages/ForgotPassword.vue'),
    },
    {
        path: '/auth/:provider/callback',
        component: {
            template: '<div class="auth-component"></div>'
        }
    },
    {
        path: '/login/:provider/',
        component: {
            template: '<div class="auth-component"></div>'
        }
    },


    {
        path: '/',
        component: DashboardLayout,
        children:[
            ...MishaRoutes,
            ...VolodyaRoutes,
            {
                path: '',
                name: 'home',
                component: () => import('@/pages/MainPage.vue'),
            },

            {
                path: '/wallet',
                name: 'wallet',
                component: () => import('@/pages/WalletPage.vue'),
            },
            {
                path: '/wallet/deposit',
                name: 'deposit',
                component: () => import('@/pages/WalletDepositPage.vue'),
            },
            {
                path: '/profile',
                name: 'profile',
                component: () => import('@/pages/ProfilePage.vue'),
            },
            {
                path: '/profile/settings',
                name: 'profile.settings',
                component: () => import('@/pages/ProfileSettingsPage.vue'),
            },
            {
                path: '/profile/certificates',
                name: 'profile.certificates',
                component: () => import('@/pages/ProfileCertificatesPage.vue'),
            },
            {
                path: '/investments',

                component: () => import('@/pages/PackagePage.vue'),
                children:[
                    {
                        path: '',
                        name: 'investments',
                        component: () => import('@/pages/packages/AllPackages.vue'),
                    },
                    {
                        path: 'my',
                        name:'investments.my',
                        component: () => import('@/pages/packages/MyPackages.vue'),
                    }
                ]
            },
            {
                path: '/partners',
                component: () => import('@/pages/partners/PartnersLayout.vue'),
                children:[
                    {
                        path: '',
                        name: 'partners',
                        component: () => import('@/pages/partners/PartnersPage.vue'),
                    },

                    {
                        path: 'team',
                        children:[
                            {
                                path: '',
                                name: 'partners.team',
                                component: () => import('@/pages/partners/PartnersTeamPage.vue'),
                            },
                            {
                                path: 'structure',
                                name: 'partners.team.structure',
                                component: () => import('@/pages/partners/PartnersTeamStructurePage.vue'),
                            },
                        ]
                    },
                    {
                        path: 'instruments',
                        name: 'partners.instruments',
                        component: () => import('@/pages/partners/PartnersInstrumentsPage.vue'),
                    },
                    {
                        path: 'education',
                        name: 'partners.education',
                        component: () => import('@/pages/partners/PartnersEducationPage.vue'),
                    },
                    //{
                    //    path: 'materials',
                    //    name: 'partners.materials',
                    //    component: () => import('@/pages/partners/PartnersMaterialsPage.vue'),
                    //},
                ]
            },
            {
                path: 'manual-payment/:id',
                name: 'manual-payment',
                component: () => import('@/components/ManualPayment.vue'),
            },

            {
                path: 'representation',
                name: 'representation',
                component: () => import('@/pages/RepresentationPage.vue'),
            },

            {
                path: 'news',
                name: 'news',
                component: () => import('@/pages/NewsPage.vue'),
            },
            {
                path: 'news/:id',
                name: 'news.id',
                component: () => import('@/pages/NewsSinglePage.vue'),
            },

            {
                path: '/admin',
                component: () => import('@/pages/admin/AdminLayout'),
                children:[
                    {
                        path: '',
                        name: 'admin',
                        component: () => import('@/pages/admin/InvestorBasePage'),
                    },

                    {
                        path: 'finance',
                        name: 'admin.finance',
                        component: () => import('@/pages/admin/FinancePage.vue'),
                    },
                    {
                        path: 'withdrawal',
                        name: 'admin.withdrawal',
                        component: () => import('@/pages/admin/WithdrawalOfFoundsPage'),
                    },
                    {
                        path: 'payment-requests',
                        name: 'admin.payment-requests',
                        component: () => import('@/pages/admin/PaymentRequestsPage'),
                    },

                ]
            },

            {
                path: 'community',
                name: 'community',
                component: () => import('@/pages/CommunityPage.vue'),
            },
            {
                path: 'contacts',
                name: 'contacts',
                component: () => import('@/pages/ContactsPage.vue'),
            },
            {
                path: 'about',
                name: 'about',
                component: () => import('@/pages/AboutPage.vue'),
            },


            /// просмотр доступных иконок:
            {
                path: '/allicons',
                name: 'allicons',
                component: () => import('@/pages/AlliconsPage.vue'),
            },
            {
                path: '*',
                redirect:  {
                        name: 'home'
                    }

            }
        ]
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.onError((error) => {
    if (/Loading chunk(?:.*)failed\.[  ]?(?:\r|\n|\r\n)/gim.test(error.message)) {
        //  console.log('Loading chunk failed. Page reload ')
        window.location.reload();
    }
})

export default router
