<template>
    <div class="wrapper">

        <div class="modal_title">{{ transaction.reason }}</div>

        <LoaderAnimation v-if="isLoading"/>

        <div v-if="transactionData">

            <div class="details reward" v-if="transaction.reason === 'reward'">
                <div class="top">
                    <div class="t">{{$t('paymentdetails.reward_partner_purchase')}}</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.payment.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.date')}}:</b> {{ formatDate(transactionData.payment.created_at, 'full') }}</div>
                    <div class="item"><b>{{$t('paymentdetails.reward_amount')}}:</b> {{ transactionData.payment.sum }} $</div>
                </div>
                <div class="maindesc">
                    <div class="t">{{$t('paymentdetails.purchased_package')}}:</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.packet_payment.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.purchase_date')}}:</b> {{ formatDate(transactionData.packet_payment.created_at, 'full') }}</div>
                    <div class="item"><b>{{$t('paymentdetails.user')}}:</b> {{ transactionData.user_from.first_name }} {{ transactionData.user_from.second_name }}</div>
                    <div class="item"><b>{{$t('paymentdetails.referral_level')}}:</b> {{ transactionData.user_from.distance }}</div>
                    <div class="item"><b>{{$t('paymentdetails.package_cost')}}:</b> {{ Math.abs(transactionData.packet_payment.sum) }} $</div>
                    <div class="item"><b>{{$t('paymentdetails.reward_percentage')}}:</b> {{ getPersent(transactionData.packet_payment.sum, transactionData.payment.sum) }}</div>
                </div>
                <div class="adddesc" v-if="transactionData.payment.data.canceled">
                    {{$t('paymentdetails.cancelled_reward_notice')}} {{ transactionData.payment.sum }} $
                </div>
            </div>


            <div class="details reward_regional_lead" v-if="transaction.reason === 'reward_regional_lead'">
                <div class="top">
                    <div class="t">{{$t('paymentdetails.regional_representative_reward')}}</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.payment.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.date')}}:</b> {{ formatDate(transactionData.payment.created_at, 'full') }}</div>
                    <div class="item"><b>{{$t('paymentdetails.reward_amount')}}:</b> {{ transactionData.payment.sum }} $</div>
                </div>
                <div class="maindesc">
                    <div class="t">{{$t('paymentdetails.purchased_package')}}:</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.packet_payment.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.purchase_date')}}:</b> {{ formatDate(transactionData.packet_payment.created_at, 'full') }}</div>
                    <div class="item"><b>{{$t('paymentdetails.user')}}:</b> {{ transactionData.user_from.first_name }} {{ transactionData.user_from.second_name }}</div>
                    <div class="item"><b>{{$t('paymentdetails.referral_level')}}:</b> {{ transactionData.user_from.distance }}</div>
                    <div class="item"><b>{{$t('paymentdetails.package_cost')}}:</b> {{ Math.abs(transactionData.packet_payment.sum) }} $</div>
                    <div class="item"><b>{{$t('paymentdetails.reward_percentage')}}:</b> {{ getPersent(transactionData.packet_payment.sum, transactionData.payment.sum) }}</div>
                </div>
            </div>


            <div class="details recalc_ref_structure" v-if="transaction.reason === 'recalculation referral structure'">
                <div class="top">
                    <div class="t">{{$t('paymentdetails.cancel_reward_due_structure_change')}}</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.payment.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.date')}}:</b> {{ formatDate(transactionData.payment.created_at, 'full') }}</div>
                </div>
                <div class="maindesc">
                    <div class="t">{{$t('paymentdetails.cancelled_reward')}}:</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.reward.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.date')}}:</b> {{ formatDate(transactionData.reward.created_at, 'full') }}</div>
                    <div class="item"><b>{{$t('paymentdetails.amount')}}:</b> {{ transactionData.reward.sum }} $</div>
                </div>
                <div class="adddesc">{{$t('paymentdetails.reward_renewal_notice')}}</div>
            </div>

            <div class="details transfer_to_user" v-if="transaction.reason === 'transfer to user'">
                <div class="top">
                    <div class="t">{{$t('paymentdetails.user_transfer')}}</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.payment.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.account')}}:</b> {{ transactionData.payment.type }}</div>
                    <div class="item"><b>{{$t('paymentdetails.date')}}:</b> {{ formatDate(transactionData.payment.created_at, 'full') }}</div>
                </div>
                <div class="maindesc">
                    <div class="t">{{$t('paymentdetails.transferred_to_user')}}:</div>
                    <div class="item">{{ transactionData.user_to.first_name }} {{ transactionData.user_to.second_name }}</div>
                    <div class="item"><b>{{$t('paymentdetails.amount')}}:</b> {{ Math.abs(transactionData.payment.sum) }} $</div>
                </div>
            </div>

            <div class="details transfer_from_user" v-if="transaction.reason === 'transfer from user'">
                <div class="top">
                    <div class="t">{{$t('paymentdetails.received_transfer')}}</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.payment.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.account')}}:</b> {{ transactionData.payment.type }}</div>
                    <div class="item"><b>{{$t('paymentdetails.date')}}:</b> {{ formatDate(transactionData.payment.created_at, 'full') }}</div>
                </div>
                <div class="maindesc">
                    <div class="t">{{$t('paymentdetails.received_from_user')}}:</div>
                    <div class="item">{{ transactionData.user_from.first_name }} {{ transactionData.user_from.second_name }}</div>
                    <div class="item"><b>{{$t('paymentdetails.amount')}}:</b> {{ Math.abs(transactionData.payment.sum) }} $</div>
                </div>
            </div>

            <div class="details transfer" v-if="transaction.reason === 'transfer'">
                <div class="top">
                    <div class="t" v-if="transactionData.payment.type == 'main'">{{$t('paymentdetails.transfer_main_to_partner')}}</div>
                    <div class="t" v-if="transactionData.payment.type == 'partner'">{{$t('paymentdetails.transfer_partner_to_main')}}</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.payment.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.date')}}:</b> {{ formatDate(transactionData.payment.created_at, 'full') }}</div>
                    <div class="item"><b>{{$t('paymentdetails.amount')}}:</b> {{ Math.abs(transactionData.payment.sum) }} $</div>
                </div>
            </div>


            <div class="details transfer" v-if="transaction.reason === 'packet_buy'">
                <div class="top">
                    <div class="t">{{$t('paymentdetails.package_purchase')}}</div>
                    <div class="item"><b>{{$t('paymentdetails.operation_id')}}:</b> {{ transactionData.payment.id }}</div>
                    <div class="item"><b>{{$t('paymentdetails.account')}}:</b> {{ transactionData.payment.type }}</div>
                    <div class="item"><b>{{$t('paymentdetails.date')}}:</b> {{ formatDate(transactionData.payment.created_at, 'full') }}</div>
                    <div class="item"><b>{{$t('paymentdetails.amount')}}:</b> {{ Math.abs(transactionData.payment.sum) }} $</div>
                </div>
            </div>



        </div>



        <div class="button_wrap">
            <div @click="close" class="base-button">{{ $t('common.ok') }}</div>
        </div>


    </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {mapGetters, mapMutations} from "vuex";
import LoaderAnimation from "@/assets/animations/LoaderAnimation";
import {formatDate} from "@/utils/helpers";

export default {
    name: "PaymentDetailsModal",
    components: {
        LoaderAnimation,
    },
    setup() {
        const {t} = useI18n({useScope: 'global'});
        return {
            t,
        }
    },
    props:{
        transaction:{
            default: false
        },
    },
    data() {
        return {
            isLoading: true,
            transactionData: null,


            valid: false,
            successful: false,

        }
    },
    computed: {
        ...mapGetters({
            user: 'user/userGetter',
        }),

    },

    methods: {
        ...mapMutations({
            setUser: 'user/setUser',
        }),
        formatDate,
        getPersent(sum, reward){
            return Math.abs(reward * 100 / sum);
        },
        close(){
            this.$emit('close');
        }
    },
    mounted() {
        this.$store.dispatch('payments/getPaymentDetails', this.transaction.id).then(data => {
            this.transactionData = data;
            console.log('data', data);
        }).catch(e=>console.error(e)).finally(() => this.isLoading = false)

    },
}
</script>


<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;

.t {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    width: 100%;
}
.top {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;
    font-size: 14px;
}
.maindesc {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;
    font-size: 14px;
}
.adddesc {
    font-size: 14px;
    font-style: italic;
}


.button_wrap {
    display: flex;
    justify-content: center;
}

::v-deep .lds-spinner{
    position: relative;
    top: 5px;
    margin-left: 1%;
}
::v-deep .lds-spinner div:after {
    background: #c0cacf;
}


</style>
